<template>
  <common-dropdown
    v-if="isAuthed"
    x="right"
    max-height="min(700px, calc(95vh - 64px))"
    data-testid="notifications"
  >
    <template #button>
      <span class="flex items-center" @click="handleOpen">
        <notifications-button :amount="unreadNotificationQty" :icon="data.icon">
          {{ data.text }}
        </notifications-button>
      </span>
    </template>

    <template #items>
      <NotificationsMarkAsRead v-if="unreadNotificationQty" />

      <div v-if="fetchingFailed" class="sticky top-0 z-50 bg-white px-2 pt-2">
        <p
          class="block min-w-[200px] max-w-[300px] whitespace-normal text-red sm:min-w-0 sm:whitespace-nowrap"
        >
          {{ $t('header.notifications-center.loading-notifications-error') }}
        </p>
        <ElementsButton
          class="my-2 ml-auto text-sm !text-onPrimary"
          text-color="primary"
          size="tiny"
          :disabled="loading.notifications"
          @click="
            getNotifications(
              { offset: '0', limit: `${notifications.length || 10}` },
              true
            )
          "
        >
          {{ $t('header.notifications-center.reload') }}
          <Icon
            v-if="loading.notifications"
            data-testid="load-more-loading"
            name="eos-icons:loading"
            class="ml-1 h-4 w-4"
          />
        </ElementsButton>
      </div>

      <Notification
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
        data-testid="notification"
      >
        <div class="flex items-center">
          <Icon
            v-if="templates[notification.type]"
            :name="NOTIFICATION_ICONS[templates[notification.type].style]"
            class="mr-1 h-6 w-6 min-w-[24px]"
          />

          <span class="flex flex-col">
            {{ templates[notification.type]?.body ?? '' }}

            <span v-if="notification.car" class="opacity-60">
              {{ notification.car.name }}
            </span>

            <span class="opacity-80">
              {{ notification.timestamp }}
            </span>
          </span>
        </div>
      </Notification>

      <NotificationsLoadMore />

      <HeadlessMenuItem
        v-if="!notifications.length && !fetchingFailed"
        disabled
      >
        <span
          class="block p-2 text-primary"
          data-testid="notifications-empty-state"
        >
          {{ $t('header.notifications-center.lack-of-notifications') }}
        </span>
      </HeadlessMenuItem>
    </template>
  </common-dropdown>

  <LazyCommonDialogAddToHomeScreen
    v-if="showInfoAboutAddingToHomeScreen && isAuthed"
    :opened="showInfoAboutAddingToHomeScreen"
    @close="showInfoAboutAddingToHomeScreen = false"
  />
</template>

<script lang="ts" setup>
import { useNotificationStore } from '@autobid/nuxt-pinia-store/store/useNotificationStore'
import { storeToRefs } from 'pinia'
import type { StrapiComponentProps } from '@autobid/strapi-integration/typescript/strapi/Strapi'
import type { StrapiElementsLinkWrapper } from '@autobid/strapi-integration/typescript/strapi/elements/LinkWrapper'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { computed, onMounted, ref } from 'vue'
import { useNotificationsSocket } from '@autobid/ui/composables/useNotificationsSocket'
import { NOTIFICATION_ICONS } from '@autobid/ui/constants/NOTIFICATION_ICONS'
import NotificationsButton from './NotificationsButton.vue'
import NotificationsLoadMore from './NotificationsLoadMore.vue'
import NotificationsMarkAsRead from './NotificationsMarkAsRead.vue'
import Notification from './Notification.vue'

interface Props {
  data: StrapiComponentProps<StrapiElementsLinkWrapper>
}

defineProps<Props>()

const { isAuthed } = useAutobidAuth()
const {
  notifications,
  amountOfNotificationsToSync,
  templates,
  fetchingFailed,
  loading
} = storeToRefs(useNotificationStore())
const { getNotifications } = useNotificationStore()

useNotificationsSocket()

const unreadNotificationQty = computed(
  () =>
    notifications.value.filter((el) => Number(el.status) === 0).length +
    amountOfNotificationsToSync.value
)

const deferGetNotifications = () => {
  // defer the request; sometime there is an error when there is a lot of request at once e.g. at the contact form
  setTimeout(() => {
    getNotifications({ limit: '50', offset: '0' })
  }, 300)
}

const ADD_HOME_SCREEN_LC_KEY = 'showInfoAboutAddingToHomeScreen'
const showInfoAboutAddingToHomeScreen = ref(false)
const checkInfo = () => {
  if (!process.client || !isAuthed.value) {
    return
  }
  if (typeof window.Notification !== 'undefined') return
  const setInfoToLocalStorage = () => {
    localStorage.setItem(
      ADD_HOME_SCREEN_LC_KEY,
      JSON.stringify({ expiresAt: Date.now() + 1000 * 60 * 60 * 24 * 7 })
    )
  }
  const stringInfoData = localStorage.getItem(ADD_HOME_SCREEN_LC_KEY)
  if (stringInfoData) {
    try {
      const infoData = JSON.parse(stringInfoData)
      if (infoData.expiresAt && infoData.expiresAt < Date.now()) {
        showInfoAboutAddingToHomeScreen.value = true
        setInfoToLocalStorage()
      }
      return
    } catch {
      // ignore
    }
  }
  showInfoAboutAddingToHomeScreen.value = true
  setInfoToLocalStorage()
}

const handleOpen = async () => {
  if (!amountOfNotificationsToSync.value) return

  await getNotifications({ offset: '0', limit: '50' }, true)

  amountOfNotificationsToSync.value = 0
}

onMounted(() => {
  if (isAuthed.value) {
    deferGetNotifications()
  }

  checkInfo()
})
</script>
