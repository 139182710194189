<template>
  <component :is="COMPONENTS[data.wrapper]" :data="data" />
</template>

<script lang="ts" setup>
import type { StrapiComponentProps } from '@autobid/strapi-integration/typescript/strapi/Strapi'
import type { StrapiElementsLinkWrapper } from '@autobid/strapi-integration/typescript/strapi/elements/LinkWrapper'
import Notifications from '@autobid/ui/components/layout/header/partials/notifications/Index.vue'
import Login from '@autobid/ui/components/layout/header/partials/WrapperLogin.vue'
import Logout from '@autobid/ui/components/layout/header/partials/WrapperLogout.vue'
import type { VueComponent } from '@autobid/ui/types/Vue'

interface Props {
  data: StrapiComponentProps<StrapiElementsLinkWrapper>
}

defineProps<Props>()

const COMPONENTS: Record<StrapiElementsLinkWrapper['wrapper'], VueComponent> = {
  Login,
  Logout,
  Notifications
}
</script>
