import { useNotificationStore } from '@autobid/nuxt-pinia-store/store/useNotificationStore'
import { storeToRefs } from 'pinia'
import { usePushNotification } from '@autobid/ui/composables/usePushNotification'

type Data = {
  type: number
  eventInput?: 'premium'
}

export const useAddNotification = () => {
  const { sendPushNotification } = usePushNotification()
  const { getTemplates } = useNotificationStore()
  const { templates, fetchingFailed } = storeToRefs(useNotificationStore())
  const { t } = useI18n()

  const addNotification = async (data: Data) => {
    if (data.eventInput === 'premium') return

    if (!Object.keys(templates.value).length) {
      try {
        await getTemplates()
      } catch {
        sendPushNotification(
          t('header.notifications-center.loading-notifications-error')
        )
        fetchingFailed.value = true
        return
      }
    }

    if (!templates.value?.[data.type]) {
      return
    }

    sendPushNotification(templates.value[data.type].body)
  }

  return { addNotification }
}
