<template>
  <span class="relative text-inherit xl:mr-3.5">
    <CommonIcon v-if="icon" :name="icon" />

    <transition
      enter-from-class="opacity-0 scale-50"
      enter-active-class="duration-300"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-active-class="duration-300"
      leave-to-class="opacity-0 scale-50"
    >
      <span
        v-if="amount"
        class="absolute -right-1 -top-1 flex h-5 items-center justify-center rounded-full bg-red px-0.5 text-xs text-white"
        data-testid="notifications-qty"
        :class="{
          'w-5': amount < MAX_QTY
        }"
      >
        <span class="relative top-[1px]">
          {{ amount >= MAX_QTY ? `+${MAX_QTY}` : amount }}
        </span>
      </span>
    </transition>
  </span>
  <span class="hidden xl:block">
    <slot />
  </span>
</template>

<script lang="ts" setup>
interface Props {
  amount: number
  icon?: string
}

defineProps<Props>()

const MAX_QTY = 50
</script>
