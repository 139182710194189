type ExtendedNotificationOptions = {
  onUnsupported?: () => any
} & NotificationOptions

/**
  Handles native notifications correctly for both app running in the browser as well as installed as PWA
 */
export function showNativeNotification(
  title: string,
  { onUnsupported, ...options }: ExtendedNotificationOptions
) {
  if (!('Notification' in window)) {
    console.error('This browser does not support notifications.')
    onUnsupported?.()
    return
  }

  if (Notification.permission === 'granted') {
    pushPolyNotify(title, options)
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        pushPolyNotify(title, options)
      }
    })
  }
}

function pushPolyNotify(title: string, options: NotificationOptions) {
  navigator.serviceWorker.getRegistration().then((registration) => {
    if (registration && 'showNotification' in registration) {
      registration.showNotification(title, options)
    } else {
      // eslint-disable-next-line no-new
      new Notification(title, options)
    }
  })
}
