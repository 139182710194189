<template>
  <layout-header-partials-link
    v-if="!isAuthed"
    class="items-center"
    as="button"
    data-testid="login-link"
    @click="signIn"
  >
    {{ data.text }}

    <template v-if="data.icon" #icon>
      <CommonIcon :name="data.icon" class="h-9 w-9" />
    </template>
  </layout-header-partials-link>
</template>

<script lang="ts" setup>
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import type { StrapiComponentProps } from '@autobid/strapi-integration/typescript/strapi/Strapi'
import type { StrapiElementsLinkWrapper } from '@autobid/strapi-integration/typescript/strapi/elements/LinkWrapper'

interface Props {
  data: StrapiComponentProps<StrapiElementsLinkWrapper>
}

defineProps<Props>()

const { isAuthed, signIn } = useAutobidAuth()
</script>
