import { showNativeNotification } from '@autobid/ui/utils/showNativeNotification'

export const usePushNotification = () => {
  const push = usePush()

  const sendPushNotification = (notification: string) => {
    if (!notification.length) {
      return
    }

    if (document.visibilityState === 'visible') {
      push.info(notification)
      return
    }
    showNativeNotification('Autobid', { body: notification })
  }

  return { sendPushNotification }
}
