<template>
  <NotificationsListElement
    :unread="false"
    as="div"
    class="text-center"
    data-testid="mark-all-notifies-as-read"
    @click="readAll"
  >
    <span :class="{ 'opacity-50': loading.readAll }">
      <Icon name="octicon:read-24" class="relative -top-[1px] mr-1 text-base" />
      {{ $t('header.notifications-center.mark-all-as-read') }}
    </span>

    <Icon
      v-if="loading.readAll"
      data-testid="mark-all-notifies-as-read-loading"
      name="eos-icons:loading"
      class="ml-1 h-4 w-4"
    />
  </NotificationsListElement>
</template>

<script lang="ts" setup>
import { useNotificationStore } from '@autobid/nuxt-pinia-store/store/useNotificationStore'
import { storeToRefs } from 'pinia'
import NotificationsListElement from './NotificationsListElement.vue'

const { loading } = storeToRefs(useNotificationStore())
const { readAll } = useNotificationStore()
</script>
