<template>
  <layout-header-partials-link
    v-if="isAuthed && tokenData"
    class="items-center"
    as="button"
    data-testid="logout-btn"
    @link-click="() => signOut()"
  >
    {{ data.text }}

    <template v-if="data.icon" #icon>
      <CommonIcon :name="data.icon" class="h-9 w-9" />
    </template>

    <template #beforeLink>
      <span class="mr-3.5 hidden lg:block" data-testid="desktop-nickname">
        {{ tokenData.nickname }}
      </span>
    </template>

    <template #afterLink>
      <span v-if="isAuthed && isBidBlocked">
        <common-dropdown x="left">
          <template #button="{ open }">
            <div
              ref="bidBlockedBtn"
              :data-open="open"
              class="ml-4 text-red"
              data-testid="desktop-bid-block-icon"
            >
              <CommonIconBlock />
            </div>
          </template>

          <template #items>
            <LayoutHeaderPartialsBiddingBlockedReason />
          </template>
        </common-dropdown>
      </span>
    </template>
  </layout-header-partials-link>
</template>

<script lang="ts" setup>
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import type { StrapiComponentProps } from '@autobid/strapi-integration/typescript/strapi/Strapi'
import type { StrapiElementsLinkWrapper } from '@autobid/strapi-integration/typescript/strapi/elements/LinkWrapper'
import { ref, onMounted } from 'vue'
import { useToken } from '@autobid/nuxt-auth/src/composables/useToken'
import { usePageHeader } from '@autobid/ui/composables/usePageHeader'
import { useUserHasBidBlocked } from '@autobid/ui/composables/useIsUserHasBidBlocked'

const bidBlockedBtn = ref<HTMLButtonElement>()

interface Props {
  data: StrapiComponentProps<StrapiElementsLinkWrapper>
}

defineProps<Props>()

const { isAuthed, signOut } = useAutobidAuth()
const { tokenData } = useToken()
const { openReasonOnAuthorize } = usePageHeader()
const isBidBlocked = useUserHasBidBlocked()

onMounted(() => {
  openReasonOnAuthorize(bidBlockedBtn.value)
})
</script>
