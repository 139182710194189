import { onMounted } from 'vue'
import { useAutobidAuth } from '@autobid/nuxt-auth/src/composables/useAutobidAuth'
import { useWebSocketStore } from '@autobid/nuxt-pinia-store/store/useWebSocketStore'
import { useNotificationStore } from '@autobid/nuxt-pinia-store/store/useNotificationStore'
import { storeToRefs } from 'pinia'
import { useAddNotification } from './notifications/useAddNotification'

export const useNotificationsSocket = () => {
  const { isAuthed } = useAutobidAuth()
  const { startListen } = useWebSocketStore()
  const { addNotification } = useAddNotification()
  const { amountOfNotificationsToSync } = storeToRefs(useNotificationStore())

  const listenNotificationsEvents = () => {
    if (!isAuthed.value) return

    if (typeof window.Notification !== 'undefined') {
      Notification.requestPermission()
    }

    const LIST_OF_EVENTS = [
      {
        templateType: 1,
        name: 'premium.CAR_OVERBID'
      },
      {
        templateType: 2,
        name: 'premium.CAR_WATCHED_SOON_IN_HOTBID'
      },
      {
        templateType: 3,
        name: 'premium.CAR_ACTIVE_SOON_IN_HOTBID'
      },
      {
        templateType: 4,
        name: 'premium.USER_BOUGHT_CAR'
      },
      {
        templateType: 5,
        name: 'premium.USER_NOT_BOUGHT_CAR'
      },
      {
        templateType: 6,
        name: 'premium.DOCUMENT_PICKUP'
      },
      {
        templateType: 7,
        name: 'premium.USER_SOLD_CAR'
      },
      {
        templateType: 8,
        name: 'premium.CAR_RESERVED'
      },
      {
        templateType: 9,
        name: 'premium.USER_NOT_SOLD_CAR'
      },
      {
        templateType: 10,
        name: 'premium.USER_SEARCH_CRITERIA_FOUND_CAR'
      },
      {
        templateType: 11,
        name: 'premium.DOCUMENT_INVOICE'
      },
      {
        templateType: 12,
        name: 'premium.USER_BOUGHT_CAR_RESERVED'
      },
      {
        templateType: 13,
        name: 'premium.CAR_WATCHED_FINISHED'
      },
      {
        templateType: 14,
        name: 'premium.TRANSPORT_REMINDER'
      }
    ]

    for (const eventData of LIST_OF_EVENTS) {
      startListen(
        eventData.name,
        'notification-center',
        (event: { input?: 'premium' }) => {
          addNotification({
            type: eventData.templateType,
            eventInput: event.input
          })
        }
      )
    }

    startListen('premium.USER_NEW_NOTIFICATION', 'notification-center', () => {
      amountOfNotificationsToSync.value++
    })
  }

  onMounted(() => {
    listenNotificationsEvents()
  })
}
