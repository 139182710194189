import { defineStore } from 'pinia'
import { removeReference } from '@autobid/strapi-integration/utils/helpers'
import { ref } from 'vue'
import { useCustomFetch } from '@autobid/ui/composables/useHttp'
import type {
  NotificationInterface,
  NotificationTemplates
} from '@autobid/nuxt-pinia-store/types/Notification'

type GetNotificationsParams = {
  limit?: string
  offset?: string
}

type LoadingTypes = 'notifications' | 'readAll'

export const useNotificationStore = defineStore('useNotification', () => {
  const loading = ref<Record<LoadingTypes, boolean>>({
    notifications: false,
    readAll: false
  })
  const fetchingFailed = ref(false)
  const hasMoreNotifications = ref(false)
  const amountOfNotificationsToSync = ref(0)
  const notifications = ref<NotificationInterface[]>([])
  const templates = ref<NotificationTemplates>({})
  const { locale } = useI18n()
  const { $customFetch } = useCustomFetch()

  const getTemplates = async () => {
    if (Object.keys(templates.value).length) return templates.value

    const data = await $customFetch<{
      templates: NotificationTemplates
    }>('/api/backend', {
      method: 'POST',
      body: {
        queryApi: 'webapi',
        queryUrl: '/v1/notifications/templates',
        queryCK: `common:${locale.value}:notificationTemplates`,
        queryCKE: 10 * 24 * 60 * 60, // 10 days
        headers: {
          'Accept-Language': locale.value
        }
      }
    })

    if (data) {
      templates.value = data.templates
    }

    return templates.value
  }

  const getNotifications = async (
    params?: GetNotificationsParams,
    refreshCurrentNotifies = false
  ) => {
    if (
      loading.value.notifications ||
      (params?.offset &&
        notifications.value.length > Number(params.offset) &&
        !refreshCurrentNotifies)
    ) {
      return
    }

    loading.value.notifications = true

    try {
      const searchParams = Object.keys(params ?? {}).length
        ? new URLSearchParams(params).toString()
        : ''
      const queryUrl = `/v1/notifications/get${
        searchParams.length ? `?${searchParams}` : ''
      }`

      const data = await $customFetch<{ notify?: NotificationInterface[] }>(
        '/api/backend',
        {
          method: 'POST',
          body: {
            queryApi: 'webapi',
            queryUrl
          }
        }
      )

      if (data?.notify?.length) {
        await getTemplates()

        notifications.value = refreshCurrentNotifies
          ? data.notify
          : [...notifications.value, ...data.notify]

        hasMoreNotifications.value =
          data.notify?.length >= Number(params?.limit ?? 1)
      } else {
        hasMoreNotifications.value = false
      }

      fetchingFailed.value = false
    } catch {
      fetchingFailed.value = true
    } finally {
      loading.value.notifications = false
    }

    return notifications.value
  }

  const optimisticMarkNotifiesAsRead = (ids: string[]) => {
    const stateBeforeChange = removeReference(notifications.value)

    notifications.value = notifications.value.map((el) => {
      return {
        ...el,
        status: ids.includes(el.id) ? '1' : el.status
      }
    })

    return {
      undoOptimisticChange: () => {
        notifications.value = stateBeforeChange
      }
    }
  }

  const readNotification = async (id: string) => {
    const { undoOptimisticChange } = optimisticMarkNotifiesAsRead([id])

    const data = await $customFetch<{ status?: string }>('/api/backend', {
      method: 'POST',
      body: {
        queryApi: 'webapi',
        queryMethod: 'PUT',
        queryUrl: `/v1/notifications/read/${id}`
      }
    })

    if (data?.status !== 'ok') {
      undoOptimisticChange()
    }
  }

  const readAll = async () => {
    if (loading.value.readAll) return

    loading.value.readAll = true

    const ids = notifications.value.map((el) => el.id)
    const { undoOptimisticChange } = optimisticMarkNotifiesAsRead(ids)

    const data = await $customFetch<{ status?: string }>('/api/backend', {
      method: 'POST',
      body: {
        queryApi: 'webapi',
        queryMethod: 'PUT',
        queryUrl: '/v1/notifications/read-all'
      }
    })

    if (data?.status !== 'ok') {
      undoOptimisticChange()
    }

    loading.value.readAll = false
  }

  return {
    loading,
    fetchingFailed,
    hasMoreNotifications,
    amountOfNotificationsToSync,
    notifications,
    templates,
    getTemplates,
    getNotifications,
    readNotification,
    readAll
  }
})
