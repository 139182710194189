<template>
  <NotificationsListElement
    :unread="Number(notification.status) === 0"
    @click="markAsReadAndNavigate"
  >
    <slot />
  </NotificationsListElement>
</template>

<script lang="ts" setup>
import { useAppStore } from '@autobid/nuxt-pinia-store/store/useAppStore'
import { useNotificationStore } from '@autobid/nuxt-pinia-store/store/useNotificationStore'
import { storeToRefs } from 'pinia'
import type { NotificationInterface } from '@autobid/nuxt-pinia-store/types/Notification'
import { useCarsStore } from '@autobid/nuxt-pinia-store/store/useCarsStore'
import { useCar } from '@autobid/ui/composables/useCar'
import { useAuctionPagesLink } from '@autobid/ui/composables/useAuctionPagesLink'
import { useOpenLink } from '@autobid/ui/composables/useOpenLink'
import NotificationsListElement from './NotificationsListElement.vue'

interface Props {
  notification: NotificationInterface
}

const props = defineProps<Props>()

const { loading } = storeToRefs(useAppStore())
const { APP_ID } = useRuntimeConfig().public
const { templates } = storeToRefs(useNotificationStore())
const { carsData } = storeToRefs(useCarsStore())
const { readNotification } = useNotificationStore()
const push = usePush()
const { t } = useI18n()
const { open } = useOpenLink()
const { getLink } = useAuctionPagesLink(Number(props.notification.car?.app_id))
const { prefetchCar } = useCar({ type: 'barometer' })

const getCarOrError = () => {
  return (
    carsData.value?.items?.[+props.notification.car?.id] ??
    carsData.value?.metadata?.errors?.find(
      (el) => el.id === +props.notification.car?.id
    )
  )
}

const markAsReadAndNavigate = async () => {
  const template = templates.value[props.notification.type]

  if (Number(props.notification.status) === 0) {
    readNotification(props.notification.id)
  }

  if (!template) {
    push.warning(t('header.notifications-center.unable-to-open-related-page'))
    return
  }

  if (props.notification.car?.app_id) {
    const carId = Number(props.notification.car.id)
    let carOrError = getCarOrError()

    if (!carOrError) {
      loading.value = true
      await prefetchCar({ id: carId })
      loading.value = false
    }

    carOrError = getCarOrError()

    if (!carOrError) {
      push.warning(t('header.notifications-center.the-car-does-not-exist'))
      return
    }

    if ('error_code' in carOrError) {
      push.error(
        carOrError.message ??
          t(`error-page.${carOrError.error_code}.description`)
      )
      return
    }

    const isSameApp = props.notification.car.app_id === String(APP_ID)
    if (isSameApp) {
      const barometerUrl = getLink({
        page: 'barometer',
        slug: carOrError.slug
      })

      open(barometerUrl)
    } else if (props.notification.car.path) {
      window.location.href = props.notification.car.path
    }

    return
  }

  let link = template.link[APP_ID] || template.link.default || ''

  if (link.length) {
    const replaceMap = {
      '{id}': props.notification.id,
      '{car.path}': props.notification.car?.path
    }

    for (const key in replaceMap) {
      link = link.replace(key, replaceMap[key])
    }

    open(link, true)
  }
}
</script>
